import { configService } from '@ovotech/energy-cx';
import React, { ReactNode, useEffect, useState } from 'react';
import { LoadingSpinner } from '@/src/components';
import {
  IS_KAPI_SUPPLY_POINTS_ENABLED,
  KAPI_PAYOUTS_API,
  KAPI_USAGE_READS_API,
  KAPI_METER_READS_API_ENABLED,
} from '@/src/constants/feature-flags';
import { optimizely, useFeature } from '@/src/utils/optimizely';

type Props = {
  children: ReactNode;
};

export const KapiFeature = ({ children }: Props) => {
  const config = configService.getConfig();
  const [loaded, setLoaded] = useState(false);

  const [isKapiSupplyPointsEnabled] = useFeature(
    IS_KAPI_SUPPLY_POINTS_ENABLED,
    {
      enabled: false,
    },
  );

  const [isKapiPayoutsApiEnabled] = useFeature(KAPI_PAYOUTS_API, {
    enabled: true,
  });

  const [isKapiUsageReadsApiEnabled] = useFeature(KAPI_USAGE_READS_API, {
    enabled: true,
  });

  const [isKapiMeterReadsApiEnabled] = useFeature(
    KAPI_METER_READS_API_ENABLED,
    {
      enabled: false,
    },
  );

  useEffect(() => {
    if (!loaded) {
      optimizely
        .onReady()
        .then(({ success }) => {
          if (success) {
            const kapiConfig = {
              accountSummary: false,
              plan: false,
              refunds: isKapiPayoutsApiEnabled,
              supplyPoints: isKapiSupplyPointsEnabled,
              usageReads: isKapiUsageReadsApiEnabled,
            };

            const meterReadsConfig = {
              kapiOnly: !!isKapiMeterReadsApiEnabled,
            };

            const shouldUpdateKapiConfig =
              JSON.stringify(config.kapi) !== JSON.stringify(kapiConfig);
            const shouldUpdateMeterReadsConfig =
              JSON.stringify(config.meterReads) !==
              JSON.stringify(meterReadsConfig);

            // Don't set config if it's already the value we want.
            if (shouldUpdateKapiConfig || shouldUpdateMeterReadsConfig) {
              configService.setConfig({
                ...config,
                meterReads: meterReadsConfig,
                kapi: kapiConfig,
              });
            }

            setLoaded(true);
          } else {
            throw new Error('Optimizely init failed');
          }
        })
        .catch(() => {
          // render as normal if optimizely fails.
          setLoaded(true);
        });
    }
  }, [
    config,
    loaded,
    isKapiSupplyPointsEnabled,
    isKapiPayoutsApiEnabled,
    isKapiUsageReadsApiEnabled,
    isKapiMeterReadsApiEnabled,
  ]);

  return (
    <div data-testid="kapi-feature">
      {loaded ? children : <LoadingSpinner />}
    </div>
  );
};
