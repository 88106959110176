import {
  Card,
  Margin,
  P,
  PrimaryCTAButton,
  Radio,
  RadioField,
  SecondaryCTAButton,
  SelectField,
  TextLink,
} from '@ovotech/nebula';
import { AnalyticsClick, useAnalytics } from '@ovotech/ui-tools';
import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { SectionHeader } from '../../components/SectionHeader';
import { RequiredFieldsError } from '../../types';
import { ReactComponent as EVCarIcon } from '@/src/resources/car.svg';
import { ReactComponent as LinkIcon } from '@/src/resources/link.svg';
import { ReactComponent as SolarPanelIcon } from '@/src/resources/solar-panel.svg';
import { mqMediumDown } from '@/src/utils/mediaQuery';
import {
  COMPATIBLE_EV_CHARGERS_LINK,
  EV,
  getVehicleInfo,
  isNotListedSelected,
  useChargeAnytimeContext,
  useEligibilityQuery,
  useVehicleOptions,
  VEHICLE_ENQUIRY_LINK,
  yearOptions,
} from '@monovo/ev-anytime';
import { PageHeader } from '@/src/pages/AnytimeEligibility/components/PageHeader';
import { PageWithNavigation } from '@/src/components';
import { useHistory } from 'react-router-dom-v5';
import { ROUTE_ANYTIME_ELIGIBILITY } from '@/src/constants/routes';
import { Loading } from '@/src/pages/AnytimeEligibility/components/Loading';
import { TechnicalGlitchPage } from '@/src/pages/AnytimeEligibility/pages/TechnicalGlitchPage/TechnicalGlitchPage';

const CardContentWrapper = styled.div`
  max-width: 600px;
`;

const SectionContainer = styled.div`
  margin-bottom: 25px;
`;

const SectionDivider = styled.div`
  ${({ theme: { space } }) => `
    height: 0;
    width: 100%;
    margin: ${space[6]} 0;
    border-bottom: 1px solid #D1D6DE
  `}
`;

const StyledP = styled(P)`
  color: #2f3749;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  ${mqMediumDown(`
    flex-direction: column-reverse;
  `)};
`;

const containsTrueValue = (values: Record<string, boolean | undefined>) =>
  Object.values(values).some(v => v);

export const VehicleAndHomeSetupQuestions = () => {
  const history = useHistory();
  const { dispatch: analyticsDispatch } = useAnalytics();
  const { modelOptions, makeOptions, isLoading, isError } = useVehicleOptions();

  const {
    smartCharger,
    make,
    setMake,
    model,
    setModel,
    year,
    setYear,
    deviceId,
    setDeviceId,
    setType,
    hasInternetAccess,
    setHasInternetAccess,
    hasSolarPanels,
    setHasSolarPanels,
  } = useChargeAnytimeContext();

  const { refetch: checkEligibility, isFetching: isFetchingEligibility } =
    useEligibilityQuery();

  const [formErrors, setFormErrors] = useState<RequiredFieldsError>({
    make: false,
    model: false,
    year: false,
    hasSolarPanels: false,
    hasInternetAccess: false,
  });

  useEffect(() => {
    analyticsDispatch({
      type: 'view',
      name: EV.ELIGIBILITY
        .EVS_OVO_CHARGE_ANYTIME_ELIGIBILITY_HOME_SET_UP_VIEWED,
      properties: { chargerName: smartCharger },
    });
    // `dispatch` method is not memoized, and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smartCharger]);

  useEffect(() => {
    analyticsDispatch({
      type: 'view',
      name: 'EVS - EVS OVO Charge Anytime D2V Home Set Up Viewed',
      properties: { chargerName: smartCharger },
    });
    // `dispatch` method is not memoized, and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smartCharger]);

  const models = useMemo(() => {
    if (!make) return [];
    return modelOptions(make);
  }, [make, modelOptions]);

  const isMakeListed = !isNotListedSelected(make);
  const isModelListed = !isNotListedSelected(model);

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const { data: eligibility } = await checkEligibility();
    const isEligible = eligibility?.compatibilityStatus === 'COMPATIBLE';
    const reasonsForIncompatibility =
      eligibility?.reasonsForIncompatibility ?? [];

    const errors = {
      make: !make,
      model: isMakeListed && !model,
      year: isMakeListed && isModelListed && !year,
      hasSolarPanels: hasSolarPanels === null,
      hasInternetAccess: hasInternetAccess === null,
    };

    const formHasErrors = containsTrueValue(errors);

    setFormErrors(errors);

    if (formHasErrors) return;

    analyticsDispatch({
      type: 'click',
      name: EV.ELIGIBILITY
        .EVS_OVO_CHARGE_ANYTIME_ELIGIBILITY_HOME_SET_UP_SUBMIT_CLICKED,
      properties: {
        smartChargerSelection: smartCharger,
        make: make,
        model: model,
        year: year,
        deviceId: deviceId,
        internetAccess: hasInternetAccess,
        solarPanels: hasSolarPanels,
      },
    });

    analyticsDispatch({
      type: 'click',
      name: 'EVS - EVS OVO Charge Anytime D2V Home Set Up submit clicked',
      properties: {
        make,
        model,
        year,
        deviceId,
        hasInternetAccess,
        hasSolarPanels,
      },
    });

    analyticsDispatch({
      type: 'view',
      name: isEligible
        ? EV.ELIGIBILITY.EVS_OVO_CHARGE_ANYTIME_CONFIRMATION_ELIGIBILITY_VIEWED
        : EV.ELIGIBILITY
            .EVS_OVO_CHARGE_ANYTIME_CONFIRMATION_INELIGIBILITY_VIEWED,
      properties: {
        smartChargerSelection: smartCharger,
        make,
        model,
        year,
        deviceId,
        eligibleVehicle: reasonsForIncompatibility.includes(
          'IncompatibleVehicle',
        ),
        hasSolarPanels,
        hasInternetAccess,
        hasSmartMeter: reasonsForIncompatibility.includes('NoSmartMeter'),
        hasOvoElectricity: reasonsForIncompatibility.includes(
          'NoElectricityTariff',
        ),
      },
    });

    analyticsDispatch({
      type: 'view',
      name: isEligible
        ? 'EVS - EVS OVO Charge Anytime D2V Outcome eligible Viewed'
        : 'EVS - EVS OVO Charge Anytime D2V Outcome Ineligible Viewed',
      properties: {
        smartChargerSelection: smartCharger,
        carMake: make,
        carModel: model,
        carYear: year,
        deviceId,
        internetAccess: hasInternetAccess,
        solarPanels: hasSolarPanels,
      },
    });

    if (isEligible) {
      history.push(ROUTE_ANYTIME_ELIGIBILITY.CONSENT);
    } else {
      history.push(ROUTE_ANYTIME_ELIGIBILITY.FALLOUT);
    }
  };

  const onMakeValueChange = (value: string | undefined) => {
    if (value && formErrors.make) {
      setFormErrors(existingErrors => ({ ...existingErrors, make: false }));
    }

    setMake(value ?? null);
    setModel(null);
    setYear(null);
    setDeviceId(null);
  };

  const onModelValueChange = (deviceId: string | undefined) => {
    if (deviceId && formErrors.model) {
      setFormErrors(existingErrors => ({ ...existingErrors, model: false }));
    }

    if (!isNotListedSelected(deviceId ?? '')) {
      const vehicle = getVehicleInfo(deviceId ?? '');
      setModel(vehicle?.model ?? null);
      setType(vehicle?.type ?? null);
    } else {
      setModel(deviceId ?? null);
    }
    setDeviceId(deviceId ?? null);
    setYear(null);
  };

  const onYearValueChange = (value: number | undefined) => {
    if (value && formErrors.year) {
      setFormErrors(existingErrors => ({ ...existingErrors, year: false }));
    }
    setYear(value ?? null);
  };

  const onHasInternetAccessValueChange = (value: boolean) => {
    if (formErrors.hasInternetAccess) {
      setFormErrors(existingErrors => ({
        ...existingErrors,
        hasInternetAccess: false,
      }));
    }
    setHasInternetAccess(value);
  };

  const onHasSolarPanelsValueChange = (value: boolean) => {
    if (formErrors.hasSolarPanels) {
      setFormErrors(existingErrors => ({
        ...existingErrors,
        hasSolarPanels: false,
      }));
    }
    setHasSolarPanels(value);
  };

  if (isError) {
    return <TechnicalGlitchPage header={'Check your setup'} />;
  }

  if (isLoading || isFetchingEligibility) {
    return <Loading />;
  }

  const yearOfManufactureHint = (
    <>
      <P style={{ color: 'GrayText' }}>
        If you're unsure, use{' '}
        <TextLink opensInNewWindow href={VEHICLE_ENQUIRY_LINK}>
          this tool
        </TextLink>{' '}
        to find out.
      </P>
    </>
  );
  return (
    <PageWithNavigation title="EV Charge Anytime - Eligibility">
      <PageHeader
        title="Check your setup"
        showDescription={false}
        testId="check-eligibility-header"
      />
      <form onSubmit={onSubmit}>
        <Card data-testid="eligibility-questions-body">
          <CardContentWrapper>
            <SectionContainer>
              <SectionHeader Icon={EVCarIcon}>
                What EV do you have?
              </SectionHeader>
              <StyledP>
                At the moment Charge Anytime is only compatible with a number of
                EVs, but we’re constantly working on extending the list of
                eligible vehicles.
              </StyledP>
              <Margin vertical={3}>
                <SelectField
                  id="make"
                  name="make"
                  label="Make"
                  value={make ?? ''}
                  error={formErrors.make && 'Please select a vehicle make'}
                  onValueChange={onMakeValueChange}
                  data-testid="make-dropdown"
                >
                  <option disabled value="">
                    Select make...
                  </option>
                  {makeOptions.map(make => (
                    <option id={make.value} value={make.value} key={make.value}>
                      {make.label}
                    </option>
                  ))}
                </SelectField>
              </Margin>
              {isMakeListed && (
                <Margin vertical={3}>
                  <SelectField
                    id="model"
                    name="model"
                    label="Model"
                    value={deviceId ?? ''}
                    error={formErrors.model && 'Please select a vehicle model'}
                    onValueChange={onModelValueChange}
                    data-testid="model-dropdown"
                  >
                    <option disabled value="">
                      Select model...
                    </option>
                    {models.map(model => (
                      <option
                        id={model.value}
                        value={model.value}
                        key={model.value}
                      >
                        {model.label}
                      </option>
                    ))}
                  </SelectField>
                </Margin>
              )}
              {isMakeListed && isModelListed && (
                <Margin vertical={3}>
                  <SelectField
                    id="year"
                    name="year"
                    label="Year of manufacture"
                    hint={yearOfManufactureHint}
                    value={year ? `${year}` : ''}
                    error={
                      formErrors.year && 'Please select the year of manufacture'
                    }
                    onValueChange={year => onYearValueChange(Number(year))}
                    data-testid="year-dropdown"
                  >
                    <option disabled value="">
                      Select year...
                    </option>
                    {yearOptions.map(option => (
                      <option
                        id={`${option.value}`}
                        value={option.value}
                        key={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </SelectField>
                </Margin>
              )}
            </SectionContainer>

            <SectionContainer>
              <SectionHeader Icon={LinkIcon}>
                Can your EV access the internet when you charge at home via
                Wi-Fi or mobile data?
              </SectionHeader>

              <StyledP>
                Your EV needs to be able to communicate with Charge Anytime via
                internet.
              </StyledP>
              <RadioField
                label=""
                name="hasInternetAccess"
                error={
                  formErrors.hasInternetAccess &&
                  'Please tell us if your vehicle can access the internet'
                }
                onValueChange={value =>
                  onHasInternetAccessValueChange(value === 'yes')
                }
              >
                <Radio
                  id="hasInternetAccess"
                  label="My EV can access the internet"
                  value="yes"
                  checked={hasInternetAccess === true}
                />
                <Radio
                  id="hasNoInternetAccess"
                  label="My EV doesn’t have access to the internet"
                  value="no"
                  checked={hasInternetAccess === false}
                />
              </RadioField>
            </SectionContainer>

            <SectionDivider />

            <SectionContainer>
              <SectionHeader Icon={SolarPanelIcon}>
                Do you have solar panels at home?
              </SectionHeader>

              <StyledP>
                Charge Anytime works with solar panels, as long as you have a
                compatible charger. View our{' '}
                <TextLink opensInNewWindow href={COMPATIBLE_EV_CHARGERS_LINK}>
                  list of solar compatible chargers.
                </TextLink>
              </StyledP>
              <RadioField
                label=""
                name="hasSolarPanels"
                error={
                  formErrors.hasSolarPanels &&
                  'Please tell us if you have solar panels at home'
                }
                onValueChange={value =>
                  onHasSolarPanelsValueChange(value === 'yes')
                }
              >
                <Radio
                  id="hasSolarPanels"
                  label="I have solar panels at home"
                  value="yes"
                  checked={hasSolarPanels === true}
                />
                <Radio
                  id="hasNoSolarPanels"
                  label="I don’t have solar panels at home"
                  value="no"
                  checked={hasSolarPanels === false}
                />
              </RadioField>
            </SectionContainer>

            <ButtonsContainer>
              <AnalyticsClick
                name={
                  EV.ELIGIBILITY
                    .EVS_OVO_CHARGE_ANYTIME_ELIGIBILITY_HOME_SET_UP_BACK_CLICKED
                }
              >
                <SecondaryCTAButton
                  style={{ textAlign: 'center' }}
                  type="button"
                  data-event-name={
                    'Click-EVS - EVS OVO Charge Anytime D2V Home Set Up back clicked [Orion Web]'
                  }
                  onClick={history.goBack}
                >
                  Previous: tell us about your EV charger
                </SecondaryCTAButton>
              </AnalyticsClick>
              <PrimaryCTAButton type="submit" data-testid="submit-button">
                Submit your answers
              </PrimaryCTAButton>
            </ButtonsContainer>
          </CardContentWrapper>
        </Card>
      </form>
    </PageWithNavigation>
  );
};
