import { Heading4, P, TextLink } from '@ovotech/nebula';
import React, { PropsWithChildren } from 'react';
import { FootnoteProvider, PageWithNavigation } from '@/src/components';
import {
  Container,
  Reason,
  Reasons,
  StyledSubtitle,
  StyledTitle,
} from './Fallout.styled';
import { AnalyticsClick, useAccountContext } from '@ovotech/ui-tools';
import { SMART_BOOKING_URL } from '@/src/constants/endpoints';
import {
  EV_ANYTIME_SMART_CHARGER_LINK,
  SWITCH_TO_OVO_ELECTRICITY,
} from '@/src/constants/links';
import { SkeletonCard } from '@/src/components/SkeletonLoading/SkeletonCard';
import {
  useChargeAnytimeContext,
  EV,
  useEligibilityQuery,
} from '@monovo/ev-anytime';
import { SolarPanelsIncompatible } from '@/src/pages/AnytimeEligibility/components/SolarPanelsIncompatible';
import { TechnicalGlitch } from '@/src/pages/AnytimeEligibility/components/TechnicalGlitch';
import { PageHeader } from '@/src/pages/AnytimeEligibility/components/PageHeader';
import { FalloutOther } from '@/src/pages/AnytimeEligibility/pages/Fallout/FalloutOther';
import { useChargeAnytimeBoltonState } from '@/src/pages/AnytimeEligibility/hooks/useChargeAnytimeBoltonState';
import { FalloutVolvo } from '@/src/pages/AnytimeEligibility/pages/Fallout/FalloutVolvo';
import { FalloutAnytimeV1 } from '@/src/pages/AnytimeEligibility/pages/Fallout/FalloutAnytimeV1';

const Loading = () => {
  return (
    <PageWithNavigation title="EV Charge Anytime - Eligibility">
      <PageHeader
        title="Check your setup"
        showDescription={false}
        testId="check-eligibility-header"
      />
      <SkeletonCard data-testid="loading-screen" />
    </PageWithNavigation>
  );
};

const ChooseYourCharger = () => (
  <AnalyticsClick
    name={
      EV.ELIGIBILITY
        .EVS_OVO_CHARGE_ANYTIME_OUTCOME_INELIGIBLE_GET_SMART_CHARGER_CLICKED
    }
  >
    <TextLink
      data-event-name={
        'Click-EVS - EVS OVO Drive Anytime Smart charger offer button clicked [Orion Web]'
      }
      href={EV_ANYTIME_SMART_CHARGER_LINK}
      opensInNewWindow
    >
      Choose your EV charger today
    </TextLink>
  </AnalyticsClick>
);

const PageWrapper = ({ children }: PropsWithChildren) => {
  return (
    <PageWithNavigation title="EV Charge Anytime - Eligibility">
      <PageHeader
        title="Check your setup"
        showDescription={false}
        testId="check-eligibility-header"
      />
      <Container>
        <Reasons>{children}</Reasons>
      </Container>
    </PageWithNavigation>
  );
};

export const Fallout = () => {
  const {
    isError,
    isFetching,
    eligibility: { loss },
  } = useChargeAnytimeContext();
  const {
    anytimeBoltonInitialized,
    payAsYouDriveBolton,
    isFetching: isCABoltonFetching,
  } = useChargeAnytimeBoltonState();
  const { accountId } = useAccountContext();

  const {
    data: eligibility,
    isFetching: isFetchingEligibility,
    isError: isEligibilityQueryError,
  } = useEligibilityQuery();
  const reasons = (eligibility?.reasonsForIncompatibility ?? []).filter(
    r => r !== 'IncompatibleCharger',
  );

  if (isFetching || isCABoltonFetching || isFetchingEligibility) {
    return <Loading />;
  }

  if (isError || isEligibilityQueryError) {
    return (
      <PageWrapper>
        <TechnicalGlitch />
      </PageWrapper>
    );
  }

  if (anytimeBoltonInitialized) {
    return (
      <PageWrapper>
        <FalloutAnytimeV1 />
      </PageWrapper>
    );
  }

  if (payAsYouDriveBolton) {
    return (
      <PageWrapper>
        <FalloutVolvo />
      </PageWrapper>
    );
  }

  const renderFallout = () => {
    const smartMeterBookingRoute = `${SMART_BOOKING_URL}?accountId=${accountId}&utm_source=anytimeEligibility&utm_medium=MyOVO&utm_campaign=smart-meter-booking`;

    return (
      <PageWrapper>
        {reasons.includes('Other') || loss ? (
          <FalloutOther />
        ) : (
          <Reason>
            <StyledTitle>Your setup isn’t compatible right now.</StyledTitle>
            <StyledSubtitle>
              {reasons.length && reasons.length > 1
                ? `Here are ${reasons.length} things you can do to get Charge Anytime`
                : 'Here’s what you can do to get Charge Anytime'}
            </StyledSubtitle>
          </Reason>
        )}
        {reasons.includes('NoSmartMeter') && (
          <Reason data-testid="smart-meter-offer">
            <Heading4>Save money with a smart meter</Heading4>
            <P>
              Charge Anytime needs a smart meter that sends half-hourly readings
              to connect your EV or charger to your OVO account, and the grid.
              If you don’t already have an electricity smart meter, we’ll
              install one for free.
            </P>
            <AnalyticsClick
              name={
                EV.ELIGIBILITY
                  .EVS_OVO_CHARGE_ANYTIME_OUTCOME_INELIGIBLE_GET_SMART_METER_CLICKED
              }
            >
              <TextLink
                data-event-name={
                  'EVS - EVS OVO Charge Anytime D2V Outcome Ineligible Get Smart Meter clicked'
                }
                opensInNewWindow
                href={smartMeterBookingRoute}
              >
                Get a smart meter
              </TextLink>
            </AnalyticsClick>
          </Reason>
        )}
        {reasons.includes('NoElectricityTariff') && (
          <Reason data-testid={'gas-only'}>
            <Heading4>Get electricity with OVO</Heading4>
            <P>
              At the moment you’re on a gas-only plan. Switch to OVO for your
              electricity and add Charge Anytime to your plan for free.
            </P>
            <AnalyticsClick
              name={
                EV.ELIGIBILITY
                  .EVS_OVO_CHARGE_ANYTIME_OUTCOME_INELIGIBLE_GET_ELECTRICITY_CLICKED
              }
            >
              <TextLink
                data-event-name={
                  'EVS - EVS OVO Drive Anytime Electricity offer button clicked'
                }
                href={SWITCH_TO_OVO_ELECTRICITY}
                opensInNewWindow
              >
                Get an electricity quote
              </TextLink>
            </AnalyticsClick>
          </Reason>
        )}
        {reasons.includes('IncompatibleVehicle') && (
          <Reason data-testid="incompatible-car">
            <Heading4>Connect through a smart EV charger</Heading4>
            <P>
              Your EV isn’t compatible right now, but you could still get Charge
              Anytime through a compatible smart EV charger – and we can install
              it for you.
            </P>
            <ChooseYourCharger />
          </Reason>
        )}
        {reasons.includes('IncompatibleWithSolar') && (
          <Reason data-testid="has-solar-panels">
            <SolarPanelsIncompatible />
          </Reason>
        )}
        {reasons.includes('NoInternetAccess') && (
          <Reason data-testid="has-no-internet-access">
            <Heading4>
              Make sure your EV connects to the internet while smart charging
            </Heading4>
            <P>
              If your EV has an internet connection, make sure you park where
              you can get a clear signal.
            </P>
            <P>
              If your EV doesn’t have an internet connection, you could still
              get Charge Anytime through one of our compatible smart EV chargers
              – and we’ll install it too.
            </P>
            <ChooseYourCharger />
          </Reason>
        )}
      </PageWrapper>
    );
  };

  return (
    <FootnoteProvider markerType="number">
      <div>{renderFallout()}</div>
    </FootnoteProvider>
  );
};
