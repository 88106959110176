import { useEligibilityQuery } from './useEligibilityQuery';
import { useChargeAnytimeContext } from '../lib/charge-anytime-context';
import { PromoType } from '../lib/anytime-vehicles';
import { VOLVO_PROMO_FORM } from '../lib/constants';

interface EligiblePromotions {
  isEligibleVolvo: boolean;
  volvoRedirectUrl: string | null;
  isEligibleVWG: boolean;
}

export const useEligiblePromotions = (): EligiblePromotions => {
  const { data: eligibility } = useEligibilityQuery();
  const {
    chargeAnytimeApi,
    isEligibleVolvo: isEligibleVolvoFromContext,
    isEligibleVWG: isEligibleVWGFromContext,
  } = useChargeAnytimeContext();
  if (!chargeAnytimeApi.config.eligibilityEndpointEnabled) {
    // @todo Remove this block with ZPBB-4288
    return {
      isEligibleVolvo: !!isEligibleVolvoFromContext,
      volvoRedirectUrl:
        isEligibleVolvoFromContext === PromoType.CAN_LINK_TO_VOLVO_PROMO
          ? VOLVO_PROMO_FORM
          : null,
      isEligibleVWG: !!isEligibleVWGFromContext,
    };
  }
  const promotions = eligibility?.eligiblePromotions ?? [];
  const volvoPromo = promotions.find((promotion) =>
    ['VolvoFreeMiles2024', 'VolvoFreeMiles2024ZohoForm'].includes(
      promotion.promotionName
    )
  );
  const volvoFwdUrl = volvoPromo?.promotionForwardUrl;
  const isEligibleVolvo = volvoPromo !== undefined;
  const vwgPromo = promotions.find(
    (promotion) => promotion.promotionName === 'VWGFreeMiles2024'
  );
  const isEligibleVWG = vwgPromo !== undefined;

  return {
    isEligibleVolvo,
    volvoRedirectUrl: volvoFwdUrl ?? null,
    isEligibleVWG,
  };
};
