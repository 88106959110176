import {
  CTAButton,
  Disclosure,
  Heading2,
  Li,
  Margin,
  P,
  SecondaryCTAButton,
  TextLink,
  Ul,
} from '@ovotech/nebula';
import { AnalyticsClick, useAnalytics } from '@ovotech/ui-tools';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom-v5';
import { PageHeader } from '../../components/PageHeader';
import {
  LoadingStyledCard,
  StyledTermsCheckbox,
  StyledUl,
  ButtonGroupWrapper,
} from './DataSharingAndTCConsent.styled';
import { PageWithNavigation } from '@/src/components';
import { ButtonGroup } from '@/src/components/ButtonGroup/ButtonGroup';
import {
  CHARGE_ANYTIME_TERMS_AND_CONDITIONS_LINK,
  KALUZA_PRIVACY_POLICY_WEB_LINK,
  OVO_PRIVACY_POLICY_LINK,
} from '@/src/constants/links';
import { usePayAsYouDriveProduct } from '@/src/pages/AnytimeEligibility/hooks/usePayAsYouDriveProduct';
import { SkeletonCard } from '@/src/components/SkeletonLoading/SkeletonCard';
import { TechnicalGlitch } from '@/src/pages/AnytimeEligibility/components/TechnicalGlitch';
import {
  badRequestErrors,
  isServerError,
  getVolvoTrackingEventName,
  isVWGOrderNumberValid,
  ChargeAnytimeErrorResponse,
  useChargeAnytimeContext,
  EV,
  useEligibilityQuery,
  useEligiblePromotions,
} from '@monovo/ev-anytime';
import { useFeature, OptimizelyContext } from '@/src/utils/optimizely';
import {
  CHARGE_ANYTIME_API,
  VWG_ORDER_NUMBER_FIELD,
  VWG_PROMO_BOX_UPDATE,
} from '@/src/constants/feature-flags';
import { useChargeAnytimeAcquisitionMutation } from '@/src/api/use-charge-anytime-acquisition-mutation';
import { AxiosError } from 'axios';
import { ROUTE_ANYTIME_ELIGIBILITY } from '@/src/constants/routes';
import { VolvoPromo } from '@/src/pages/AnytimeEligibility/pages/DataSharingAndTCConsent/promos/VolvoPromo';
import { VolvoCheckbox } from '@/src/pages/AnytimeEligibility/pages/DataSharingAndTCConsent/promos/VolvoCheckbox';
import { VwgPromoBox } from '@/src/pages/AnytimeEligibility/pages/DataSharingAndTCConsent/promos/VwgPromoBox';
import { VwgCheckbox } from '@/src/pages/AnytimeEligibility/pages/DataSharingAndTCConsent/promos/VwgCheckbox';
import { VwgPromoBoxBottom } from '@/src/pages/AnytimeEligibility/pages/DataSharingAndTCConsent/promos/VwgPromoBoxBottom';

export const DataSharingAndTCConsent = () => {
  const { dispatch: analyticsDispatch } = useAnalytics();
  const { optimizely } = useContext(OptimizelyContext);
  const caDataConsentTitleTestEnabled = optimizely?.isFeatureEnabled(
    'ca_data_consent_title_test',
  );
  const [consentShareEVData, setConsentShareEVData] = useState(false);
  const [consentCATerms, setConsentCATerms] = useState(false);
  const [consentErrors, setConsentErrors] = useState(false);
  const { activateProduct, isReady, productActivationMutation } =
    usePayAsYouDriveProduct();
  const acquisitionMutation = useChargeAnytimeAcquisitionMutation();
  const { isError, error } = acquisitionMutation;

  const [isVWGOrderNumberFieldEnabled] = useFeature(VWG_ORDER_NUMBER_FIELD);
  const [isChargeAnytimeApiEnabled] = useFeature(CHARGE_ANYTIME_API);
  const [isVWGPromoBoxUpdateEnabled] = useFeature(VWG_PROMO_BOX_UPDATE);
  const history = useHistory();

  const isChargeAnytimeGlitch =
    isChargeAnytimeApiEnabled && isError && isServerError(error);

  const {
    smartCharger,
    make,
    model,
    deviceId,
    type,
    year,
    hasSolarPanels,
    hasInternetAccess,
    vwgOrderNumber,
    setVwgOrderNumberError,
    validPromoCode,
  } = useChargeAnytimeContext();
  const { data: eligibility, isFetching: isFetchingEligibility } =
    useEligibilityQuery();
  const integrationType = eligibility?.integration?.integrationType;
  const integrationForwardUrl = eligibility?.integration?.integrationForwardUrl;
  const { isEligibleVWG, isEligibleVolvo } = useEligiblePromotions();

  useEffect(() => {
    analyticsDispatch({
      type: 'view',
      name: EV.ELIGIBILITY.EVS_OVO_CHARGE_ANYTIME_CONSENT_VIEWED,
      properties: {
        DataConsentTitleTest: caDataConsentTitleTestEnabled,
      },
    });
    // `dispatch` method is not memoized, and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    analyticsDispatch({
      type: 'view',
      name: 'EVS - EVS OVO Charge Anytime D2V Confirmation Eligibility Consent Viewed',
      properties: {
        DataConsentTitleTest: caDataConsentTitleTestEnabled,
      },
    });
    // `dispatch` method is not memoized, and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    !smartCharger ||
    !make ||
    productActivationMutation.isError ||
    isChargeAnytimeGlitch
  ) {
    return (
      <PageWithNavigation title="Charge Anytime">
        <TechnicalGlitch />
      </PageWithNavigation>
    );
  }

  const changeConsentShareEVData = () => {
    setConsentShareEVData(!consentShareEVData);
  };

  const changeConsentCATerms = () => {
    setConsentCATerms(!consentCATerms);
  };

  const shouldSendVwgOrderNumber =
    isVWGOrderNumberFieldEnabled && isEligibleVWG;

  const validateVwgOrderNumber = () => {
    if (vwgOrderNumber && shouldSendVwgOrderNumber) {
      return isVWGOrderNumberValid(vwgOrderNumber);
    }
    return true;
  };

  const sendVolvoTracking = () => {
    const eventName = getVolvoTrackingEventName(
      isEligibleVolvo,
      !!hasSolarPanels,
      smartCharger,
      validPromoCode,
    );
    if (eventName) {
      analyticsDispatch({
        type: 'view',
        name: eventName,
      });
    }
  };

  const confirmConsent = async () => {
    optimizely?.track('charge_anytime_data_consent_confirm_clicked');
    const isVWGOrderNumberValid = validateVwgOrderNumber();
    if (!isVWGOrderNumberValid) {
      setVwgOrderNumberError(
        'That’s not a valid order number. Please check and try again.',
      );
    }
    if (consentShareEVData && consentCATerms && isVWGOrderNumberValid) {
      sendVolvoTracking();
      const vwgOrderNumberProps = shouldSendVwgOrderNumber
        ? { vwgOrderNumber }
        : {};
      analyticsDispatch({
        type: 'click',
        name: EV.SIGNUP.EVS_OVO_CHARGE_ANYTIME_CONSENT_CONFIRM_CLICKED,
        properties: {
          chargerName: smartCharger,
          carMake: make,
          carModel: model,
          carDeviceId: deviceId,
          carYear: year,
          internetAccess: hasInternetAccess,
          solarPanels: hasSolarPanels,
          isEligibleVolvo: isEligibleVolvo,
          validPromoCode: validPromoCode,
          DataConsentTitleTest: caDataConsentTitleTestEnabled,
          ...vwgOrderNumberProps,
        },
      });
      analyticsDispatch({
        type: 'click',
        name: 'EVS - EVS OVO Drive Anytime consent yes clicked',
        properties: {
          chargerName: smartCharger,
          carMake: make,
          carModel: model,
          carDeviceId: deviceId,
          carYear: year,
          internetAccess: hasInternetAccess,
          solarPanels: hasSolarPanels,
          isEligibleVolvo: isEligibleVolvo,
          validPromoCode: validPromoCode,
          DataConsentTitleTest: caDataConsentTitleTestEnabled,
          ...vwgOrderNumberProps,
        },
      });

      if (isChargeAnytimeApiEnabled) {
        try {
          await acquisitionMutation.mutateAsync({
            charger: smartCharger,
            make,
            model,
            type,
            year,
            hasInternetAccess: !!hasInternetAccess,
            hasSolarPanels: !!hasSolarPanels,
            ...vwgOrderNumberProps,
          });
          analyticsDispatch({
            type: 'view',
            name: EV.ELIGIBILITY.EVS_OVO_CHARGE_ANYTIME_ORDER_CREATED,
          });
        } catch (error: any) {
          const { status, response } =
            error as AxiosError<ChargeAnytimeErrorResponse>;
          if (status === 400) {
            const errors = badRequestErrors(response?.data ?? []) as {
              vwgOrderNumber: string;
            };
            analyticsDispatch({
              type: 'view',
              name: EV.ELIGIBILITY.EVS_OVO_CHARGE_ANYTIME_ORDER_FAILED,
              properties: { errors: errors },
            });
            setVwgOrderNumberError(errors.vwgOrderNumber);
          } else {
            analyticsDispatch({
              type: 'view',
              name: EV.ELIGIBILITY.EVS_OVO_CHARGE_ANYTIME_ORDER_FAILED,
              properties: { error: error.message },
            });
          }
          return;
        }
      }
      if (integrationType === 'Volvo') {
        try {
          await activateProduct(validPromoCode);
          setConsentErrors(false);
          history.push(ROUTE_ANYTIME_ELIGIBILITY.VOLVO_APP);
        } catch {
          // Handled by productActivationMutation.isError below
          analyticsDispatch({
            type: 'view',
            name: EV.ELIGIBILITY
              .EVS_OVO_CHARGE_ANYTIME_CONFIRMATION_TECHNICAL_GLITCH_VIEWED,
          });
        }
      } else if (integrationType === 'Ohme') {
        window.location.assign(
          integrationForwardUrl?.ohmeRedirect ??
            (process.env.NX_EV_ANYTIME_OHME_IDENTITY_URL as string), // @todo Remove NX_EV_* urls with ZPBB-4288
        );
      } else if (integrationType === 'Indra') {
        window.location.assign(
          integrationForwardUrl?.indraRedirect ??
            (process.env.NX_EV_ANYTIME_KALUZA_IDENTITY_URL as string), // @todo Remove NX_EV_* urls with ZPBB-4288
        );
      } /* KALUZA APP JOURNEY */ else {
        setConsentErrors(false);
        history.push(ROUTE_ANYTIME_ELIGIBILITY.CHARGE_ANYTIME_APP);
      }
    } else {
      setConsentShareEVData(consentShareEVData);
      setConsentCATerms(consentCATerms);
      setConsentErrors(true);
    }
  };

  if (
    !isReady ||
    productActivationMutation.isLoading ||
    acquisitionMutation.isLoading ||
    isFetchingEligibility
  ) {
    return (
      <PageWithNavigation title="Charge Anytime">
        <SkeletonCard />
      </PageWithNavigation>
    );
  }

  return (
    <PageWithNavigation title="Charge Anytime">
      <PageHeader
        title={
          caDataConsentTitleTestEnabled
            ? 'Good news. You can get Charge Anytime.'
            : 'Success! You can get Charge Anytime.'
        }
        showDescription={false}
      />
      <LoadingStyledCard data-testid="anytime-consent-page">
        <Heading2>Data sharing</Heading2>
        <P>
          To bring you Charge Anytime, we need to share the following data with
          our trusted tech partner, Kaluza, and your EV charging partner:
        </P>
        <Ul>
          <Li>Your EV charger ID</Li>
          <Li>Your EV details</Li>
          <Li>Your EV energy use</Li>
        </Ul>
        <Margin top={4}>
          <StyledTermsCheckbox
            id="dataSharingEVData"
            label="I agree for my EV data to be shared with OVO, Kaluza and my EV charging partner. "
            checked={consentShareEVData}
            onChange={changeConsentShareEVData}
            error={
              !consentShareEVData &&
              consentErrors &&
              'Please tick ok to continue'
            }
            data-testid="dataSharingEVData"
          />
        </Margin>
        <Margin top={4}>
          <Disclosure name="more-about-data" title="More about data sharing">
            <P>
              We share your data with Kaluza and your charging partner (this is
              your EV charger manufacturer or your EV manufacturer). We do this
              to work out the most accurate charging costs for you. Find out
              exactly what information will be shared using the links below.
            </P>
            <StyledUl>
              <Li>
                <TextLink
                  href={KALUZA_PRIVACY_POLICY_WEB_LINK}
                  opensInNewWindow
                >
                  See Kaluza Privacy Policy
                </TextLink>
              </Li>
              <Li>
                <TextLink href={OVO_PRIVACY_POLICY_LINK} opensInNewWindow>
                  See OVO Privacy Policy
                </TextLink>
              </Li>
            </StyledUl>
          </Disclosure>
        </Margin>
        {isVWGPromoBoxUpdateEnabled && isEligibleVWG && <VwgPromoBox />}
        <Heading2>Our terms and conditions</Heading2>
        {!isEligibleVolvo && !isEligibleVWG && (
          <>
            <P>
              To add Charge Anytime you’ll need to agree to our terms and
              conditions.
            </P>
            <StyledUl>
              <Li>
                <TextLink
                  href={CHARGE_ANYTIME_TERMS_AND_CONDITIONS_LINK}
                  opensInNewWindow
                >
                  Charge Anytime terms and conditions
                </TextLink>
              </Li>
            </StyledUl>
            <Margin top={4} bottom={8}>
              <StyledTermsCheckbox
                id="chargeAnytimeTerms"
                label="I agree to the Charge Anytime terms and conditions."
                checked={consentCATerms}
                onChange={changeConsentCATerms}
                error={
                  !consentCATerms &&
                  consentErrors &&
                  'Please tick ok to continue'
                }
                data-testid="chargeAnytimeTerms"
              />
            </Margin>
          </>
        )}
        {isEligibleVolvo && (
          <VolvoCheckbox
            checked={consentCATerms}
            setChecked={setConsentCATerms}
            isError={consentErrors}
          />
        )}
        {isEligibleVWG && (
          <VwgCheckbox
            isError={consentErrors}
            checked={consentCATerms}
            setChecked={setConsentCATerms}
          />
        )}
        {isEligibleVolvo && <VolvoPromo />}
        {!isVWGPromoBoxUpdateEnabled
          ? isEligibleVWG && <VwgPromoBoxBottom />
          : null}
        <ButtonGroupWrapper>
          <ButtonGroup>
            <AnalyticsClick
              name={EV.SIGNUP.EVS_OVO_CHARGE_ANYTIME_CONSENT_BACK_CLICKED}
            >
              <SecondaryCTAButton
                iconLeft="chevron-left"
                iconRight="off"
                onClick={history.goBack}
                data-testid="backButton"
              >
                Back
              </SecondaryCTAButton>
            </AnalyticsClick>
            <AnalyticsClick
              name={EV.SIGNUP.EVS_OVO_CHARGE_ANYTIME_CONSENT_CONFIRM_CLICKED}
            >
              <CTAButton
                data-event-name={
                  'EVS - EVS OVO Drive Anytime consent yes clicked'
                }
                onClick={confirmConsent}
                data-testid="confirmConsentButton"
              >
                Confirm
              </CTAButton>
            </AnalyticsClick>
          </ButtonGroup>
        </ButtonGroupWrapper>
      </LoadingStyledCard>
    </PageWithNavigation>
  );
};
