import React, { useEffect, useRef } from 'react';
import {
  Route,
  useLocation,
  Redirect,
  useRouteMatch,
} from 'react-router-dom-v5';

import { DataSharingAndTCConsent } from './pages/DataSharingAndTCConsent/DataSharingAndTCConsent';
import { EVAnytimeDisconnect } from '../AnytimeInlife/pages/Anytime/EVAnytimeDisconnect';
import { ChargeAnytimeSignupConfirmation } from './pages/ChargeAnytimeSignupConfirmation/ChargeAnytimeSignupConfirmation';
import { EVsInlifePageAnytime } from '../AnytimeInlife/pages/Anytime/EVsInlifePageAnytime';
import { defaultDateRedirectPath } from '../AnytimeInlife/utils';
import { SwitchWithNotFound } from '@/src/components';
import {
  CHARGE_ANYTIME_DEVICES,
  CHARGE_ANYTIME_ELIGIBILITY,
  DRIVE_ANYTIME,
  INDRA_CHARGE_ANYTIME_APP_ENABLED,
  PAY_AS_YOU_DRIVE,
  PAY_AS_YOU_DRIVE_VOLVO,
  PAY_AS_YOU_DRIVE_VWG,
} from '@/src/constants/feature-flags';
import { OptimizelyFeature, useFeature } from '@/src/utils/optimizely';
import {
  ROUTE_ANYTIME_ELIGIBILITY,
  ROUTE_CHARGE_ANYTIME_ELIGIBILITY,
} from '@/src/constants/routes';
import { SmartChargerQuestions } from '@/src/pages/AnytimeEligibility/pages/SmartChargerQuestions/SmartChargerQuestions';
import { VehicleAndHomeSetupQuestions } from '@/src/pages/AnytimeEligibility/pages/VehicleAndHomeSetupQuestions/VehicleAndHomeSetupQuestions';
import { Fallout } from '@/src/pages/AnytimeEligibility/pages/Fallout/Fallout';
import { VolvoAnytimeAppLink } from '@/src/pages/AnytimeEligibility/pages/VolvoAnytimeAppLink/VolvoAnytimeAppLink';
import { ChargeAnytimeAppLink } from '@/src/pages/AnytimeEligibility/pages/ChargeAnytimeAppLink/ChargeAnytimeAppLink';
import { useCheckEligibilityForPayAsYouDrive } from '@/src/pages/AnytimeEligibility/hooks/useCheckEligibilityForPayAsYouDrive';
import { useSelector } from 'react-redux';
import { hasSmartMeter as hasSmartMeterSelector } from '@/src/pages/Renewal/selectors';
import { ChargeAnytimeProvider } from '@monovo/ev-anytime';
import { useChargeAnytimeBoltonState } from '@/src/pages/AnytimeEligibility/hooks/useChargeAnytimeBoltonState';
import { Loading } from '@/src/pages/AnytimeEligibility/components/Loading';
import { fetchFn } from '@/src/pages/AnytimeEligibility/services/chargeAnytimeAPI';
import { useAccountContext, useCustomerContext } from '@ovotech/ui-tools';

export const routes = {
  disconnect: '/disconnect',
};

export const ChargeAnytime = () => {
  const { path } = useRouteMatch();
  const { pathname, search } = useLocation();

  const { accountId } = useAccountContext();
  const { customerId } = useCustomerContext();
  const hasSmartMeter = useSelector(hasSmartMeterSelector);
  const [isPayAsYouDriveActive] = useFeature(PAY_AS_YOU_DRIVE);
  const [isIndraChargeAnytimeAppEnabled] = useFeature(
    INDRA_CHARGE_ANYTIME_APP_ENABLED,
  );
  const [isPayAsYouDriveVolvoEnabled] = useFeature(PAY_AS_YOU_DRIVE_VOLVO);
  const [isPayAsYouDriveVWGEnabled] = useFeature(PAY_AS_YOU_DRIVE_VWG);
  const [isChargeAnytimeDeviceActive] = useFeature(CHARGE_ANYTIME_DEVICES);
  const [isChargeAnytimeEligibilityActive] = useFeature(
    CHARGE_ANYTIME_ELIGIBILITY,
  );

  const positions = useRef(Object.create(null));
  useEffect(() => {
    if (pathname in positions.current) {
      const { x, y } = positions.current[pathname];
      window.scrollTo(x, y);
    } else {
      window.scrollTo(0, 0);
    }

    const handler = () => {
      positions.current[pathname] = { x: window.scrollX, y: window.scrollY };
    };
    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, [pathname]);

  const disconnectRoute = `${path}${routes.disconnect}`;
  const hasAnytimeAllowedRoutes = [
    ROUTE_ANYTIME_ELIGIBILITY.CHARGE_ANYTIME_APP,
    ROUTE_ANYTIME_ELIGIBILITY.VOLVO_APP,
    ROUTE_ANYTIME_ELIGIBILITY.CONFIRMATION,
    disconnectRoute,
  ];
  const {
    payAsYouDriveBoltonActive,
    payAsYouDriveBoltonInitialised,
    anytimeBoltonInitialized,
    isFetching,
  } = useChargeAnytimeBoltonState();
  const canReenterJourney =
    hasAnytimeAllowedRoutes.includes(pathname) ||
    (!payAsYouDriveBoltonActive &&
      !payAsYouDriveBoltonInitialised &&
      !anytimeBoltonInitialized);

  if (isFetching) {
    return <Loading />;
  }

  return (
    <OptimizelyFeature feature={DRIVE_ANYTIME}>
      {isEnabled => {
        return (
          isEnabled && (
            <ChargeAnytimeProvider
              hasSmartMeter={hasSmartMeter}
              useCheckEligibilityForPayAsYouDrive={
                useCheckEligibilityForPayAsYouDrive
              }
              isPayAsYouDriveEnabled={isPayAsYouDriveActive}
              isPayAsYouDriveVolvoEnabled={isPayAsYouDriveVolvoEnabled}
              isPayAsYouDriveVWGEnabled={isPayAsYouDriveVWGEnabled}
              isIndraChargeAnytimeAppEnabled={isIndraChargeAnytimeAppEnabled}
              chargeAnytimeApi={{
                fetch: fetchFn,
                config: {
                  devicesEndpointEnabled: isChargeAnytimeDeviceActive,
                  eligibilityEndpointEnabled: isChargeAnytimeEligibilityActive,
                },
              }}
              accountId={accountId}
              customerId={customerId}
            >
              {!canReenterJourney ? (
                <Fallout />
              ) : (
                <SwitchWithNotFound>
                  <Redirect
                    exact={true}
                    from={`${ROUTE_CHARGE_ANYTIME_ELIGIBILITY}`}
                    to={
                      canReenterJourney
                        ? ROUTE_ANYTIME_ELIGIBILITY.SMART_CHARGER
                        : ROUTE_ANYTIME_ELIGIBILITY.FALLOUT
                    }
                  />
                  <Route
                    exact
                    path={ROUTE_ANYTIME_ELIGIBILITY.SMART_CHARGER}
                    component={SmartChargerQuestions}
                  />
                  <Route
                    exact
                    path={ROUTE_ANYTIME_ELIGIBILITY.VEHICLE_AND_HOME_SETUP}
                    component={VehicleAndHomeSetupQuestions}
                  />
                  <Route
                    exact
                    path={ROUTE_ANYTIME_ELIGIBILITY.CONSENT}
                    component={DataSharingAndTCConsent}
                  />
                  <Route
                    exact
                    path={ROUTE_ANYTIME_ELIGIBILITY.CONFIRMATION}
                    component={ChargeAnytimeSignupConfirmation}
                  />
                  <Route
                    exact
                    path={ROUTE_ANYTIME_ELIGIBILITY.CHARGE_ANYTIME_APP}
                    component={ChargeAnytimeAppLink}
                  />
                  <Route
                    exact
                    path={ROUTE_ANYTIME_ELIGIBILITY.VOLVO_APP}
                    component={VolvoAnytimeAppLink}
                  />
                  <Route
                    exact
                    path={ROUTE_ANYTIME_ELIGIBILITY.FALLOUT}
                    component={Fallout}
                  />
                  <Route
                    exact
                    path={`${path}/:year(\\d{4})/:month(\\d{2})`}
                    component={EVsInlifePageAnytime}
                  />
                  <Route
                    exact
                    path={disconnectRoute}
                    component={EVAnytimeDisconnect}
                  />
                  <Redirect
                    to={{
                      pathname: defaultDateRedirectPath(path, new Date()),
                      search,
                    }}
                  />
                </SwitchWithNotFound>
              )}
            </ChargeAnytimeProvider>
          )
        );
      }}
    </OptimizelyFeature>
  );
};

export default ChargeAnytime;
