import {
  Divider,
  StyledUl,
} from '@/src/pages/AnytimeEligibility/pages/DataSharingAndTCConsent/DataSharingAndTCConsent.styled';
import { Heading2, Li, Margin, P, TextField, TextLink } from '@ovotech/nebula';
import {
  EV,
  useChargeAnytimeContext,
  useEligiblePromotions,
  VWG_PROMO_COPY,
  VWG_PROMO_FORM,
  VWG_PROMO_HEADING,
} from '@monovo/ev-anytime';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React from 'react';
import { useFeature } from '@/src/utils/optimizely';
import { VWG_ORDER_NUMBER_FIELD } from '@/src/constants/feature-flags';

// This is older version where vwg order number field was at the bottom of the page
export const VwgPromoBoxBottom = () => {
  const {
    smartCharger,
    make,
    model,
    deviceId,
    year,
    hasSolarPanels,
    hasInternetAccess,
    vwgOrderNumber,
    setVwgOrderNumber,
    vwgOrderNumberError,
    setVwgOrderNumberError,
    validPromoCode,
  } = useChargeAnytimeContext();
  const { isEligibleVWG } = useEligiblePromotions();
  const [isVWGOrderNumberFieldEnabled] = useFeature(VWG_ORDER_NUMBER_FIELD);
  return (
    <>
      <Divider />
      <Margin bottom={8}>
        <Heading2>{VWG_PROMO_HEADING}</Heading2>
        <P>{VWG_PROMO_COPY}</P>
        <StyledUl>
          <Li>
            <AnalyticsClick
              name={EV.ELIGIBILITY.EVS_OVO_CHARGE_ANYTIME_VW_PROMO_CLICKED}
              properties={{
                smartChargerSelection: smartCharger,
                validPromoCode: validPromoCode,
                isEligibleVWG: isEligibleVWG,
                carMake: make,
                carModel: model,
                carDeviceId: deviceId,
                carYear: year,
                solarPanels: hasSolarPanels,
                internetAccess: hasInternetAccess,
              }}
            >
              {!isVWGOrderNumberFieldEnabled ? (
                <TextLink
                  data-event-name={
                    'EVS - EVS OVO Drive Anytime VW promo clicked'
                  }
                  data-event-props={JSON.stringify({
                    smartChargerSelection: smartCharger,
                    validPromoCode: validPromoCode,
                    isEligibleVWG: isEligibleVWG,
                    carMake: make,
                    carModel: model,
                    carDeviceId: deviceId,
                    carYear: year,
                    solarPanels: hasSolarPanels,
                    internetAccess: hasInternetAccess,
                  })}
                  href={VWG_PROMO_FORM}
                  opensInNewWindow
                >
                  Click here to send us your order number
                </TextLink>
              ) : (
                <TextField
                  id="chargeAnytimeVWGOrderNUmber"
                  placeholder="Enter your order number here"
                  data-testid="chargeAnytimeVWGOrderNumber"
                  label=""
                  error={!!vwgOrderNumberError}
                  hint={vwgOrderNumberError}
                  value={vwgOrderNumber}
                  onChange={e => {
                    setVwgOrderNumberError('');
                    setVwgOrderNumber(e.target.value);
                  }}
                />
              )}
            </AnalyticsClick>
          </Li>
        </StyledUl>
      </Margin>
      <Divider />
    </>
  );
};
