import React from 'react';
import { Margin, P, TextLink } from '@ovotech/nebula';
import {
  CHARGE_ANYTIME_TERMS_AND_CONDITIONS_LINK,
  VOLVO_FREE_MILES_OFFER_LINK,
} from '@/src/constants/links';
import { StyledTermsCheckbox } from '@/src/pages/AnytimeEligibility/pages/DataSharingAndTCConsent/DataSharingAndTCConsent.styled';
import { PromoCheckboxProps } from '@/src/pages/AnytimeEligibility/pages/DataSharingAndTCConsent/promos/types';

export const VolvoCheckbox = ({
  checked,
  setChecked,
  isError,
}: PromoCheckboxProps) => {
  return (
    <>
      <P>
        To add Charge Anytime and claim your free miles, you’ll need to agree to
        the terms and conditions for{' '}
        <TextLink
          href={CHARGE_ANYTIME_TERMS_AND_CONDITIONS_LINK}
          opensInNewWindow
        >
          Charge Anytime
        </TextLink>{' '}
        and the{' '}
        <TextLink href={VOLVO_FREE_MILES_OFFER_LINK} opensInNewWindow>
          Volvo free miles offer
        </TextLink>
        .
      </P>
      <Margin top={4} bottom={8}>
        <StyledTermsCheckbox
          id="chargeAnytimeTerms"
          label="I agree to the Charge Anytime and Volvo free miles offer terms."
          checked={checked}
          onChange={() => setChecked(!checked)}
          error={!checked && isError && 'Please tick ok to continue'}
          data-testid="chargeAnytimeTerms"
        />
      </Margin>
    </>
  );
};
