import { useQuery } from 'react-query';
import { useChargeAnytimeContext } from '../lib/charge-anytime-context';
import { EligibilityRequestBody, EligibilityResponse } from '../lib/types';
import { useMemo } from 'react';
import { SmartChargers } from '../lib/anytime-chargers';

export const useEligibilityQuery = () => {
  const {
    chargeAnytimeApi,
    eligibility,
    model,
    make,
    year,
    smartCharger,
    hasInternetAccess,
    hasSolarPanels,
    integrationType,
    accountId,
    customerId,
  } = useChargeAnytimeContext();

  if (!customerId || !accountId) {
    throw new Error('Missing customerId or accountId');
  }

  const chargerType = useMemo(() => {
    if (smartCharger === SmartChargers.ThreePin) {
      return 'THREE_PIN_PLUG';
    }
    if (smartCharger === SmartChargers.NotListed) {
      return 'NOT_LISTED';
    }
    return 'SMART_CHARGER';
  }, [smartCharger]);

  const eligibilityRequestBody: EligibilityRequestBody = {
    eligibilityCriteria: {
      vehicle: {
        model: model ?? '',
        manufacturer: make ?? '',
        modelYear: year ?? 0,
      },
      charger: {
        manufacturer: smartCharger,
        chargerType,
      },
      hasInternetAccess: hasInternetAccess ?? false,
      hasSolarPanels: hasSolarPanels ?? false,
    },
  };

  return useQuery<EligibilityResponse, unknown>({
    retryOnMount: false,
    retry: false,
    enabled: false, // We want this query data only on submit
    queryKey: ['charge-anytime-eligibility', accountId, customerId],
    queryFn: () => {
      if (!chargeAnytimeApi.config.eligibilityEndpointEnabled) {
        // @todo Entire if block will be removed with ZPBB-4288
        const reasonsForIncompatibility: EligibilityResponse['reasonsForIncompatibility'] =
          [];
        if (eligibility.onlyGasSupply) {
          reasonsForIncompatibility.push('NoElectricityTariff');
        }
        if (eligibility.hasNoInternetAccess) {
          reasonsForIncompatibility.push('NoInternetAccess');
        }
        if (eligibility.hasSolarPanels) {
          reasonsForIncompatibility.push('IncompatibleWithSolar');
        }
        if (eligibility.incompatibleCar) {
          reasonsForIncompatibility.push('IncompatibleVehicle');
        }
        if (eligibility.invalidOther) {
          reasonsForIncompatibility.push('Other');
        }
        if (eligibility.notSmartMeter) {
          reasonsForIncompatibility.push('NoSmartMeter');
        }

        return Promise.resolve({
          eligiblePromotions: [], // Eligible promotions are exported in useEligiblePromotions hook
          integration: {
            integrationType,
          },
          compatibilityStatus: eligibility.isEligible
            ? 'COMPATIBLE'
            : 'INCOMPATIBLE',
          reasonsForIncompatibility,
        });
      }
      return chargeAnytimeApi.fetch(
        `/v1/eligibility/customers/${customerId}/accounts/${accountId}`,
        { method: 'POST', body: eligibilityRequestBody }
      );
    },
  });
};
