import {
  EV,
  useChargeAnytimeContext,
  useEligiblePromotions,
  VOLVO_PROMO_FORM,
} from '@monovo/ev-anytime';
import {
  CTAButton,
  Field,
  Heading2,
  Li,
  Margin,
  P,
  SkeletonHeading,
  TextField,
  TextLink,
} from '@ovotech/nebula';
import {
  PromoContainer,
  StyledUl,
} from '@/src/pages/AnytimeEligibility/pages/DataSharingAndTCConsent/DataSharingAndTCConsent.styled';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React from 'react';
import { useAvailableProductsQuery } from '@/src/api';
import { PRODUCT_IDS } from '@/src/constants/products';

export const VolvoPromo = () => {
  const {
    validPromoCode,
    isPromoCodeError,
    promoCode,
    setPromoCode,
    validatePromoCode,
  } = useChargeAnytimeContext();
  const { isEligibleVolvo, volvoRedirectUrl } = useEligiblePromotions();

  const { refetch, isLoading } = useAvailableProductsQuery(
    PRODUCT_IDS.payasyoudrive,
    null,
    promoCode,
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );

  const changePromoCode: React.ChangeEventHandler<HTMLInputElement> = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPromoCode(e.target.value);
  };

  if (!isEligibleVolvo) {
    return null;
  }

  if (!volvoRedirectUrl) {
    return (
      <Margin top={4} bottom={8}>
        <Heading2>Do you have a promo code?</Heading2>
        <Field
          id="chargeAnytimePromoCodeContainer"
          label=""
          error={
            isPromoCodeError &&
            'We don’t recognise that promo code. Please check and try again.'
          }
        >
          <PromoContainer>
            <TextField
              id="chargeAnytimePromoCode"
              placeholder="Enter your code here"
              data-testid="chargeAnytimePromoCodeInput"
              label=""
              value={promoCode}
              onChange={changePromoCode}
            />
            <AnalyticsClick
              name={
                EV.ELIGIBILITY.EVS_OVO_CHARGE_ANYTIME_VOLVO_PROMO_CODE_CLICKED
              }
            >
              <CTAButton
                onClick={async () =>
                  validatePromoCode(!!promoCode && (await refetch()).isError)
                }
                data-testid="changePromoCodeButton"
              >
                Apply
              </CTAButton>
            </AnalyticsClick>
          </PromoContainer>
        </Field>
      </Margin>
    );
  }

  return (
    <>
      <Margin top={4} bottom={8}>
        <Heading2>Do you have a promo code?</Heading2>
        <StyledUl>
          <Li>
            <AnalyticsClick
              name={
                EV.ELIGIBILITY.EVS_OVO_CHARGE_ANYTIME_VOLVO_PROMO_FORM_CLICKED
              }
            >
              <TextLink href={VOLVO_PROMO_FORM} opensInNewWindow>
                Click here to send us your code
              </TextLink>
            </AnalyticsClick>
          </Li>
        </StyledUl>
      </Margin>
      {isLoading ? (
        <SkeletonHeading headingComponent={Heading2} size="90%" />
      ) : validPromoCode ? (
        <Margin top={4} bottom={8}>
          <Heading2>Thanks!</Heading2>
          <P>
            After you finish signing up for Charge Anytime in the app, we'll
            activate the promotion for you.
          </P>
        </Margin>
      ) : null}
    </>
  );
};
