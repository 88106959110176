import {
  Disclosure,
  Heading2,
  Li,
  Margin,
  P,
  TextField,
  TextLink,
} from '@ovotech/nebula';
import { VWG_FREE_MILES_OFFER_LINK } from '@/src/constants/links';
import {
  PromoCutout,
  StyledUl,
} from '@/src/pages/AnytimeEligibility/pages/DataSharingAndTCConsent/DataSharingAndTCConsent.styled';
import { AnalyticsClick } from '@ovotech/ui-tools';
import {
  EV,
  useChargeAnytimeContext,
  useEligiblePromotions,
  VWG_PROMO_FORM,
} from '@monovo/ev-anytime';
import React from 'react';
import { useFeature } from '@/src/utils/optimizely';
import { VWG_ORDER_NUMBER_FIELD } from '@/src/constants/feature-flags';

export const VwgPromoBox = () => {
  const {
    smartCharger,
    make,
    model,
    deviceId,
    year,
    hasSolarPanels,
    hasInternetAccess,
    vwgOrderNumber,
    setVwgOrderNumber,
    vwgOrderNumberError,
    setVwgOrderNumberError,
    validPromoCode,
  } = useChargeAnytimeContext();
  const { isEligibleVWG } = useEligiblePromotions();
  const [isVWGOrderNumberFieldEnabled] = useFeature(VWG_ORDER_NUMBER_FIELD);

  return (
    <PromoCutout>
      <Heading2>Our free miles offers</Heading2>
      <P>
        We’re offering some fantastic free miles promotions through our
        partnership with the Volkswagen Group.{' '}
        <TextLink href={VWG_FREE_MILES_OFFER_LINK} opensInNewWindow>
          Eligibility criteria apply.
        </TextLink>{' '}
      </P>
      <P>
        Enter your order number, and within 2 weeks we’ll let you know if you’re
        eligible for one of our free miles offers.
      </P>
      <Margin top={4}>
        <Disclosure
          name="where-can-i-find-my-order-number"
          title="Where can I find my order number?"
        >
          <P>
            Your order number is an 8-digit number, usually starting with 3. You
            can find it in the purchase agreement or order confirmation from
            your dealership. If your EV is a company car, please contact your
            fleet manager.
          </P>
        </Disclosure>
      </Margin>
      <StyledUl>
        <Li>
          <AnalyticsClick
            name={EV.ELIGIBILITY.EVS_OVO_CHARGE_ANYTIME_VW_PROMO_CLICKED}
            properties={{
              smartChargerSelection: smartCharger,
              validPromoCode: validPromoCode,
              isEligibleVWG: isEligibleVWG,
              carMake: make,
              carModel: model,
              carDeviceId: deviceId,
              carYear: year,
              solarPanels: hasSolarPanels,
              internetAccess: hasInternetAccess,
            }}
          >
            {!isVWGOrderNumberFieldEnabled ? (
              <TextLink
                data-event-name={'EVS - EVS OVO Drive Anytime VW promo clicked'}
                data-event-props={JSON.stringify({
                  smartChargerSelection: smartCharger,
                  validPromoCode: validPromoCode,
                  isEligibleVWG: isEligibleVWG,
                  carMake: make,
                  carModel: model,
                  carDeviceId: deviceId,
                  carYear: year,
                  solarPanels: hasSolarPanels,
                  internetAccess: hasInternetAccess,
                })}
                href={VWG_PROMO_FORM}
                opensInNewWindow
              >
                Click here to send us your order number
              </TextLink>
            ) : (
              <Margin top={4}>
                <TextField
                  id="chargeAnytimeVWGOrderNUmber"
                  placeholder="3"
                  data-testid="chargeAnytimeVWGOrderNumber"
                  label="Enter your 8-digit order number. This usually starts with a 3."
                  error={!!vwgOrderNumberError}
                  hint={vwgOrderNumberError}
                  value={vwgOrderNumber}
                  onChange={e => {
                    setVwgOrderNumberError('');
                    setVwgOrderNumber(e.target.value);
                  }}
                />
              </Margin>
            )}
          </AnalyticsClick>
        </Li>
      </StyledUl>
    </PromoCutout>
  );
};
